import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { Button } from "./shadcn/button";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

/*
A component to display an error message and reset the application.
This is used by Next.js error pages to display the error message and reset the application.
*/

export interface ErrorDisplayProps {
  reset: () => void;
  error?: Error & { digest?: string };
}

export const ErrorDisplay = (props: ErrorDisplayProps) => {
  // Log to datadog
  useEffect(() => {
    if (props.error) {
      datadogRum.addError(props.error);
    }
  }, [props.error]);

  // Log to appInsights
  const appInsights = useAppInsightsContext();
  useEffect(() => {
    if (props.error && appInsights) {
      appInsights.trackException({ exception: props.error });
    }
  }, [appInsights, props.error]);

  return (
    <div className="mb-10 flex min-h-full w-full grow items-center justify-center text-center">
      <div>
        <h1 className="my-3">{"We are sorry, but something went wrong."}</h1>
        <p className="my-2">
          {"Please try to "}
          <Button onClick={() => props.reset()} variant="outline" size="xs">
            {"Reset"}
          </Button>
          {" the application."}
        </p>
        <p className="my-2">
          {"If the problem persists, please reach out to the team "}
          <a
            className="text-link"
            href="mailto:mailto:data_solutions@leo-pharma.com?subject=Error%20on%20LEO%20Open%20AI&body=Please%20describe%20the%20issue%20you%20are%20having%20and%20if%20possible%20the%20steps%20to%20recreate%20the%20issue."
          >
            {"here"}
          </a>
          {"."}
        </p>
      </div>
    </div>
  );
};
