import { clsx, type ClassValue } from "clsx";
import { type CSSProperties } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getCodeStyle = (isInline: boolean): CSSProperties => {
  return {
    fontSize: "inherit!important",
    ...(isInline && {
      padding: ".25em",
      paddingLeft: ".5em",
      paddingRight: ".5em",
      display: "inline",
    }),
  };
};
